export const propuestas_proyectos = [
  {
    id: 1,
    Tipo: "Proyecto SIP-IPN 2023",
    Titulo: "Predicción de series de tiempo con algoritmo cuántico",
    Resumen: "En el presente proyecto se propone implementar un algoritmo cuántico basado en una arquitectura de una red neuronal recurrente de memoria corta a largo plazo. Esta es una posible alternativa de solución a los problemas de predicción de series de tiempo donde los datos son adquiridos con sensores electrónicos y tienen aplicaciones de control entre otras aplicaciones. Las series de tiempo a utilizar son con datos irregulares y caóticos. Se plantea construir una red neuronal de dos capas con celulas neuronales recurrentes lstm en una versión clasica así como una implementación cuántica mediante Qiskit.",
    Responsable: ["Dr. Jesús Yaljá Montiel Pérez"]
  },
  {
    id: 2,
    Tipo: "Proyecto Innovación-IPN 2023",
    Titulo: "IA para predicción ocupacional y de intensidad de luz para luminarias inteligentes",
    Resumen: "La presente propuesta de proyecto consiste en proporcionar inteligencia artificial a luminarias de interiores. Actualmente las luminarias 'inteligentes' se controlan con aplicaciones, que tienen las opciones de apagado, encendido, color, e intensidad. Otro modo de controlar las luminarias es programando las anteriores características en tiempos específicos o aleatorios. Estos procesos no son inteligentes, es con base a un control manual del usuario. Por lo que en este proyecto se propone adicionar a las luminarias algoritmos de inteligencia artificial de predicción de acciones con base a la ocupación e intensidad de iluminación de zonas de un departamento, sala de oficina, etc. La ocupación se refiere a la presencia de personas en una zona monitorizada con niveles de iluminación constantes. Es decir, si la iluminación la está proporcionando la luz natural, entonces no es necesaria un nivel de intensidad de luminaria; si la luz ambiental es parcial y existen personas en la zona, nivelar la intensidad con una luminaria. Con lo anterior los algoritmos de inteligencia artificial se puede hacer una predicción con antelación para que en ningún momento el usuario tenga que prender, apagar o tener poca o mucha iluminación; sino que, la iluminación adecuada de manera automática o realmente inteligente.",
    Responsable: ["Dr. Jesús Yaljá Montiel Pérez"]
  },
  {
    id: 3,
    Tipo: "Proyecto Innovación Alumnos-IPN 2023",
    Titulo: "Estaciones Sísmicas para Ciudades Inteligentes",
    Resumen: "En este proyecto se busca desarrollar estaciones sísmicas que puedan ser usadas en el monitoreo del estado de edificios antes, durante y después  de sísmos. Inicialmente, el funcionamiento será evaluado en las instalaciones del IPN, considerando la posibilidad de desarrollar redes de monitoreo permanentes.",
    Responsable: ["David Pérez Martínez, estudiante del DCC", "César Castrejón Peralta, estudiante de la MCIC","Oscar Roberto Chaparro Amaro, estudiante del DCC","Mario Alfredo Ibarra Carrillo, estudiante del DCC"]
  },
  {
    id: 4,
    Tipo: "Proyecto SECTEI-IPN 2021",
    Titulo: "Sistema integral para el monitoreo remoto del estado general de salud de personas y sanitizado desde su hogar",
    Resumen: "Diseñar, construir e implementar un sistema de monitoreo de salud que permita de manera remota, fácil y óptima para el personal de salud, poder dar el seguimiento de señales de signos vitales de un paciente desde casa, a través de un dispositivo no invasivo, apoyado sobre un sistema de comunicación remota para apoyar y proporcionar referencias de mediociones de variables físicas de los pacientes a los medicos en el diagnóstico del COVID-19. Así mismo, diseñar y poner en operación una caja para la desinfección de efectos personales mediante la aplicación de luz ultravioleta.",
    Responsable: ["Dr. Juan Humberto Sossa Azuela","Dr. Jesús Yaljá Montiel Pérez","Dr. Ponciano Jorge Escamilla Ambrosio","Dr. Iliac Huerta Trujillo","M. en C. Iván Rosales Martínez","M. en C. Elizabeth López Lozada","M. en C. Raúl Castillo Luna","M. en C. David Pérez Martínez","M. en C. Alberto Maldonado Romo","Ing. Juan Enrique Hernández Martínez","Ing. Juan Antonio Alfaro Juárez, UPIITA","Ing. José Joel Pérez Federico, ESCOM","Ing. Marcos León Reyes, ESCOM","Ing. Vianey Guadalupe Meza González","Dr. Jesús Montaño Luna, Hospital Regional 200 IMSS"]
  },
  {
    id: 5,
    Tipo: "Proyecto SRE-AMEXCID-IPN 2022",
    Titulo: "Sistema de monitoreo del estado de salud de pacientes COVID-19 en hospitales",
    Resumen: "Se describen los componentes y módulos del sistema de monitorización del estado de salud de pacientes COVID-19 para hospitales. El sistema de medición y envío de datos es por paciente a un sistema de base de datos en la web. Por lo que se tiene un módulo de adquisición de datos con los sensores para medir temperatura, frecuencia cardiaca, saturación de oxígeno en la sangre, frecuencia respiratoria y presión arterial. El envió de dicha información se hace vía bluetooth a una tableta donde existe un software para su administración y visualización y posteriormente la información es enviada a una base de datos en la nube para poder visualizarse por medicos responsables del paciente vía web.",
    Responsable: ["Dr. Juan Humberto Sossa Azuela","Dr. Jesús Yaljá Montiel Pérez","Dr. Iliac Huerta Trujillo","M. en C. Elizabeth López Lozada","M. en C. David Pérez Martínez","M. en C. Alberto Maldonado Romo","Lic. Diana Patricia Barragán Vázquez"]
  },
];