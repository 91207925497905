/* lista tipo JSON, 
    en esta lista se encuentran los datos de los posdoctorados   */
export const posdoctorado_data = [
    {
        ID: 1,
        Foto: require("../images/Equipo_de_trabajo/Tat’y.jpg"),/*agregar la foto a la ruta, y cambiar el nombre, si no hay poner "nada.jpg" al final*/
        Nombre: "Dr. Tat’y Mwata Velu",
        Correo: "tmwata@cic.ipn.mx",
        Link: "https://proyectosrym.cic.ipn.mx/taty/",
    },
]