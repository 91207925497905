
import styles2 from "../css/Tesis.module.css";
import ListaGrupoTrabajo from "../components/ListInvestigacion";

export const Investigacion = () => {
  return (
    <>
      <div>
        <h1  onLoad={window.scrollTo(0, 0)}>Investigación</h1>
      </div>

        <ListaGrupoTrabajo />
    </>
  );
}