
import React, { useEffect, useRef, useState } from 'react';
import styles from "../css/HomePage.module.css";
import banner1 from "../images/Banner_Images/Dia_5_Agenda.png";
import bannerFb from "../images/Banner_Images/VisitFacebook.png";
import bannerYT from "../images/Banner_Images/VisitYouTube.png";
import bannerLI from "../images/Banner_Images/VisitLinkedIn.png";
import bannerSocial from "../images/Banner_Images/VisitSocials.png";
import bannerCIC from "../images/Banner_Images/VisitCIC.png";
import reconocimientoCC from "../images/Banner_Images/Reconocimiento_CC.png";

import fiestas1 from "../images/Banner_Images/Vacaciones_2024_1.png";
import fiestas2 from "../images/Banner_Images/Vacaciones_2024_2.png";
import fiestas3 from "../images/Banner_Images/Vacaciones_2024_3.png";

import bannerRIPAISC from "../images/Banner_Images/RIPAISC.png";

import bannerExpo from "../images/Banner_Images/Exposiciones_13_Dic.png";

import bannerEIR1 from "../images/Banner_Images/EIR_Dia_5_1.png";
import bannerEIR2 from "../images/Banner_Images/EIR_Dia_5_2.png";




export const Banner_Lab = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const visible = (new Date().getDay()) % 3;

    const mes = (new Date().getMonth());

    useEffect(() => {

        const slides = document.getElementsByClassName("myBanner");
        for (let i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
    
        const showSlides = () => {
          const slides = document.getElementsByClassName("myBanner");
          for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
          }
          setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
          slides[slideIndex].style.display = "block";
        };
    
    {/*}
    const showSlides = () => {
        const slides = document.getElementsByClassName("myBanner");
        const i=0;
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
          }
          //slideIndex++;
          if (slideIndex > slides.length) {slideIndex = 1}
          slides[slideIndex-1].style.display = "block";
          //setTimeout(showSlides2, 2000); // Change image every 2 seconds
      }
        */}
        const interval = setInterval(showSlides, 6000); // Cambiar la imagen cada 3 segundos (2000 milisegundos)
    
        return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
    
      }, [slideIndex]);
    
      useEffect(() => {
        const slides = document.getElementsByClassName("myBanner");
        for (let i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
        slides[slideIndex].style.display = "block";
      }, [slideIndex]);



  return (
    <>
      <div>
        <div>
            <div>
                <div>&nbsp;</div>
                <div className='myBanner'>
                    <a href='https://femexrobotica.org/eir2024-2025/' target='new'><img src={banner1} width="100%"/></a>
                </div>
                {/****Banners para anunciar las sesiones del día siguiente*****/}
                <div className='myBanner'>
                    <a href='https://femexrobotica.org/eir2024-2025/' target='new'><img src={bannerEIR1} width="100%"/></a>
                </div>
                <div className='myBanner'>
                    <a href='https://www.ripaisc.net/' target='new'><img src={bannerRIPAISC} width="100%"/></a>
                </div>
                <div className='myBanner'>
                    <a href='https://femexrobotica.org/eir2024-2025/' target='new'><img src={bannerEIR2} width="100%"/></a>
                </div>
                <div className='myBanner'>
                    <a href='https://www.cic.ipn.mx/' target='new'><img src={bannerCIC} width="100%"/></a>
                </div>


                
                
                <div className='myBanner'>
                    <img src={bannerExpo} width="100%"/>
                </div>
                {/*
                <div className={visible === 1 ? 'myBanner' : 'hidden'}>
                    <a href='https://www.youtube.com/watch?v=CKtgoL7uTqc&t=3s' target='new'><img src={bannerYT} width="100%"/></a>
                </div>
                <div className={visible === 2 ? 'myBanner' : 'hidden'}>
                  <img src={bannerSocial} width="100%"/>
                </div>
                {/*<div className='myBanner'>
                  <a href="https://ipn.mx/gacetas/seleccion-gaceta.html?fbclid=IwY2xjawG63UxleHRuA2FlbQIxMAABHWicpnqTnmvsjIOosEfJV02IWSjTGTvASlkD5xfxrL-KHZPWcevyg2m-KA_aem_sNjHNsQDgHwaVgW2w0xeRw" target="new"><img src={reconocimientoCC} width="100%" /></a>
                </div>*/}
                {/*<div className={//miFecha === 1 ? 'myBanner' : 'hidden'}>
                    <a href='https://www.facebook.com/laboratorioRyM' target='new'><img src={//bannerFb} width="100%"/></a>
                </div>
                
                <div className={//miFecha === 2 ? 'myBanner' : 'hidden'}>
                    <a href='https://www.linkedin.com/company/laboratorio-de-rob%C3%B3tica-y-mecatr%C3%B3nica-cic/posts/?feedView=all' target='new'><img src={//bannerLI} width="100%"/></a>
                </div>*/}
                 
                  {/********Sólo se activa en Diciembre***********
                  <div className={mes === 11 ? 'myBanner' : 'hidden'}>
                    <img src={fiestas1} width="100%"/>
                </div>
                <div className='myBanner'>
                    <img src={bannerExpo}  width="100%"/>
                </div>*/}
                {/******** Activado siempre*************** */}
                
                {/********Sólo se activa en Enero************/}
                <div className={mes === 0 ? 'myBanner' : 'hidden'}>
                    <img src={fiestas3} width="100%"/>
                </div>
            </div>
        </div>
        <div>
        </div>
      </div>
    </>
  );
};