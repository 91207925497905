
import { posdoctorado_data } from "../data/posdoctorado_data"
import { useState, useEffect } from 'react';
import styles2 from "../css/GrupoTrabajo.module.css";

/* Aquí se crean los cuadros de los PosDoctorantes*/

/* se inicialisa la lista*/
function ListaGrupoInvestigacion() {

    /*Se inicia una lista de forma vacía */
    const [dataListaposdoctorado, setdataListaposdoctorado] = useState([]);

    /*Se colocan los datos en la lista*/
    useEffect(() => {
        setdataListaposdoctorado(posdoctorado_data)
    }, [])


    /*Se comprueba si las listastiene valores */
    if (dataListaposdoctorado.length === 0) {
        return <h1>No se encontró lista de Posdoctorado</h1>
    }
    /* Aquí se modifica la forma que tendrán los cuadros*/
    return (
        <>
            <div className={styles2.subtitulo}><h3>Posdoctorado:</h3></div>
            {
                dataListaposdoctorado.map((dato) => (
                    <div className={styles2.cuadroTrabajo} key={dato.ID}>
                        <div>
                            <img src={dato.Foto}
                                alt="GrupoTrabajo" />
                        </div>
                        <div>
                            <p>{dato.Nombre} </p>
                        </div>
                        <div>
                            <p>{dato.Correo}</p>
                        </div>
                        <div>
                            <p><a href={dato.Link}>{dato.Link !== "" ? "'Pagina personal'" : ""}</a></p>
                        </div>
                    </div>
                ))}
        </>
    );
}

export default ListaGrupoInvestigacion;