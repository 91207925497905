import styles from "../css/institucional.module.css";
import { propuestas_tesis } from "../data/propuestas_tesis";

export const Tesis = () => {
  return (
    <>
      <div class="col-md-1"  onLoad={window.scrollTo(0, 0)}></div>
      <div class="col-md-10">
        <h1>Propuestas de Tesis</h1>
        <hr class="red"></hr>
        <div>
        <table width="80%" border="1"  class="table table-bordered">
          <tbody>
          <tr>
            <th scope="col" align="center">&nbsp;Responsable</th>
            <th scope="col" align="center">&nbsp;Propuesta de Tesis</th>
          </tr>
          {propuestas_tesis.filter(tesis => tesis.Tesis.length > 0).map(({ id, Nombre, Correo, Tesis }) => (
            <tr>
            <td  align="center" valign="middle" id="profesor_responsable">
              <b>{Nombre}, {Correo}</b>
            </td>
            <td align="left" id="propuesta_tesis">
                {Tesis.map(({ Nivel, Tema, Descripcion }) => (
                    <>
                      <p align="left"><b>Nivel:</b> {Nivel}</p>
                      <p align="left"><b>Tema: {Tema} </b> </p>
                      <p align="left"><b>Descripción:</b> {Descripcion}</p>
                      <hr/>
                    </>
                  ))}
            </td>
            </tr>
            ))}
          </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
