/* lista tipo JSON para las imagenes del programa delfin  */
export const imagenes_delfin = [
    {
        CAT: "",
        Descripcion: "",
        Foto: require("../images/programaDelfin/induccion.JPG"),
        DesImg: "Bienvenida e inducción al CIC con el Maestro Santiago Reyes.",
    },
    {
        CAT: "Análisis de señales sísmicas",
        Descripcion: "En este proyecto los participantes analizarán el comportamiento y características de las señales sísmicas captadas por la red de sensores disponibles en México. Además, se hará una revisión de los algoritmos con los que opera actualmente el sistema mexicano; se estudiará la teoría detrás de las redes neuronales convolucionales, sus principales usos y algunas aplicaciones en Sismología y Ciencias de la Tierra, finalmente trabajarán en una red neuronal que clasifique los arribos de ondas y calcule magnitud aproximada.",
        Foto: require("../images/programaDelfin/equipo_sismos.JPG"),
        DesImg: "El equipo de trabajo del proyecto lo integra Donaldo Ayala (SS-ESCOM-IPN), César Castrejón (MCIC-CIC-IPN), Anahí Salto (P-Delfín, UPIITA-IPN) y Joseph Abundis (P-Delfín, UO).",
    },
    {
        CAT: "Computación Cuántica",
        Descripcion: "Este proyecto busca identificar el potencial de la computación cuántica en el área de finanzas, los estudiantes trabajarán en la recopilación de datos, la implementación de una red neuronal, el diseño e implementación del algoritmo de optimización cuántica, evaluación, análisis de resultados y conclusiones.",
        Foto: require("../images/programaDelfin/equipo_computacion.JPG"),
        DesImg: "El equipo de trabajo lo integran Bernardo Morales (P-Delfín, UABC), Emmanuel Omar (P-Delfín, CECyT 9-IPN), Luis Andrade (MCIC-CIC-IPN), Alejandro Pérez, (P-Delfín, UPV), Jordi González (DCC-CIC-IPN) y Alberto Maldonado (DCC-CIC-IPN, online).",
    },
    {
        CAT: "Descripción y caracterización para el procesamiento de imágenes ruidosas",
        Descripcion: "Este grupo revisará distintos modelos de ruido en imágenes, el análisis y procesamiento de imágenes y cómo eliminar el ruido implementando redes neuronales convolucionales.",
        Foto: require("../images/programaDelfin/equipo_imagenes.JPG"),
        DesImg: "Oscar Chaparro (DCC-CIC-IPN), Melba García (SS, ESFM-IPN), Miguel Martínez (DCC-CIC-IPN), Alexis Murillo (P-Delfín, CECyT 10-IPN) y Gustavo Juárez (P-Delfín, ESIME-IPN).",
    },
]