import { ImagesDelfin } from "../components/ImagesDelfin"
import logoLab from "../images/logoLab.png";
import styles from "../css/DelfinPage.module.css";
import { useState } from "react";
import { ListLinksMenu } from "../templates/NavBar";

export const Delfin = () => {
    const [isMenuActive, setMenuActive] = useState(true);

    const handleMenu = () => {
        setMenuActive(isMenuActive ? false : true);
    }

    return (
        <>
            <header className={styles.header}>
                <nav className={styles.navbar}>
                    <div className={styles.barsMenu} onClick={handleMenu}>
                        <span className={isMenuActive ? styles.activeline1Bars : undefined}></span>
                        <span className={isMenuActive ? styles.activeline2Bars : undefined}></span>
                        <span className={isMenuActive ? styles.activeline3Bars : undefined}></span>
                    </div>
                    {isMenuActive && <ListLinksMenu isMenuActive={isMenuActive} styles={styles}/>}
                </nav>
                <div className={styles.headerContenedor}>
                    <div>
                        <img
                            className={styles.headerSombra}
                            style={{ marginRight: 20 }}
                            height={150}
                            src={logoLab}
                            alt='logo delfin'
                        />
                    </div>
                    <div className={styles.headerTexto}>
                        <h2>Instituto Politécnico Nacional</h2>
                        <p>Centro de Investigación en Computación</p>
                        <p>Laboratorio de Robótica y Mecatrónica</p>
                    </div>
                </div>
            </header>
            <div className={styles.contenedorDelfinpage}>
                <div className={styles.delfinpageObjetivos}>
                    <h3 className={styles.delfinpageSubtitulo}>Programa Delfín en el Centro de Investigación en Computación</h3>
                    <p className={styles.delfinpageParrafo}>El Programa de Movilidad Delfín, XXVIII Verano de la Investigación Científica y Tecnológica del Pacífico regresa al CIC #IPN, una vez concluida la pandemia de COVID19.
                        El Dr. Jesús Yaljá Montiel Pérez, del laboratorio de Robótica y Mecatrónica del CIC IPN recibirá a ocho alumnos de otras instituciones para que trabajen en tres proyectos del 19 de junio al 4 de agosto, con el objetivo de que los estudiantes tengan un acercamiento de primera mano con el trabajo de investigación, la actividad científica y el desarrollo tecnológico.</p>
                </div>
                <div className={styles.delfinpageObjetivos}>
                    <h3 className={styles.delfinpageSubtitulo}>Instituciones de los alumnos del Programa Delfín</h3>
                    <ul className={styles.delfinpageListUl}>
                        <li>Universidad Politécnica de Victoria</li>
                        <li>Universidad Autónoma de Baja California</li>
                        <li>Universidad de Occidente</li>
                        <li>Escuela Superior de Ingeniería Mecánica y Eléctrica</li>
                        <li>Unidad Profesional Interdisciplinaria en Ingeniería y Tecnologías Avanzadas</li>
                        <li>Centro de Estudios Científicos y Tecnológicos #9</li>
                        <li>Centro de Estudios Científicos y Tecnológicos #10</li>
                    </ul>
                </div>
                <ImagesDelfin />
                <h1 className={styles.saludo}>La comunidad CIC les da la bienvenida, ¡es un gusto recibirlos!</h1>
            </div>
        </>
    );
}