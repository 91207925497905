
import foto from "../images/Equipo_de_trabajo/Juan_Sossa.jpg";

export const HumbertoS = () => {
  return (
    <>
    <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
    <div className="col-lg-1"></div>
    <div className="col-lg-10">
    <h3>Dr. Juan Humberto Sossa Azuela </h3>
          <hr class="red"/>
          <p align="right"><a href="https://sites.google.com/site/cicvision/home" target="new">Página Personal</a></p>
          <div className="col-lg-3"><img src={foto} width="100%"></img></div>
          <div className="col-lg-9">
            <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Dr. en Informática</ol>
                <ol>M. en C. en Ingeniería Eléctrica</ol>
                <ol>Ing. en Comunicaciones y Eléctrica</ol>
              </ul></p>
              <p><strong>Unidades de Aprendizaje</strong></p>
              <p><ul>
                <ol>Reconocimiento de Formas y Visión por Computadora</ol>
                <ol>Análisis de Imágenes</ol>
                <ol>Procesamiento Digital de Imágenes</ol>
                <ol>Introducción a las Redes Neuronales Artificiales</ol>
                <ol>Redes Neuronales Artificiales Avanzadas</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Reconocimiento de Patrones</li>
                <li>Análisis de Imágenes</li>
                <li>Redes Neuronales</li>
                <li>Memorias asociativas</li>
                <li>Spiking neural network</li>
                <li>Deep Learning</li>
              </ul></p>
            </div>
    </div>
    <div className="col-lg-1"></div>
    </>
  );
};

