import { imagenes_delfin } from "../data/programa_delfin";
import styles from "../css/DelfinImages.module.css"

export const ImagesDelfin = () => {
    return (
        <>
            {
                imagenes_delfin.map((imagen) => (
                    <div className={styles.contenedor} >
                        <div className={styles.headerArea}>
                            <h4 className={styles.area}>{imagen.CAT}</h4>
                            <p className={styles.descArea}>{imagen.Descripcion}</p>
                        </div>
                        <img className={styles.equipoImg} src={imagen.Foto} alt="" />
                        <p className={styles.equipoDesc} >{imagen.DesImg}</p>
                        <h3 className={imagen.CAT === "" ? styles.titulo : ""} >{imagen.CAT === "" ? "Proyectos a desarrollar" : ""}</h3>
                    </div>
                ))
            }
        </>
    );
}