export const estancias_data = [
  {
    ID: 4,
    Foto: require("../images/Estancias/jose.jpg"),
    Nombre: "José Abraham Tellez Morales",
    Alt: "Estudiante del Doctorado en Ciencias de la Computación",
    Estancia: "INSA Institut National des Sciences Appliquées Centre Val de Loire, Francia",
    Periodo: "1 mayo 2023 - 1 noviembre 2023",
    Actividades: "Deteccion automática de células a través de una base de datos de patch clamp usando yolov8 para la implementación del modelo en un microscopio DIC para la detección y seguimiento de la célula target e implementacion de un robot micromanipulador para aproximar una micropipeta a la superficies de una célula objetivo.",
  },
  {
    ID: 1,
    Foto: require("../images/Estancias/alberto.jpeg"),
    Nombre: "Alberto Maldonado Romo",
    Alt: "Estudiante del Doctorado de Ciencias Computacionales",
    Estancia: "Thomas J. Watson Research Center, NY, EUA",
    Periodo: "22 mayo 2023 - 11 agosto 2023",
    Actividades: "Colabora con el framework de Qiskit en sus diferentes formatos como es la documentación, la programación, la difusión, el ecosistema y en la parte de marketing y ser mentor y organizador de eventos y propuestas sobre computación cuántica, e iniciando una propuesta de eventos para hispanohablantes.",
  },
  {
    ID: 2,
    Foto: require("../images/Estancias/luis.jfif"),
    Nombre: "Luis Enrique Andrade Gorjoux",
    Alt: "Estudiante de la Maestría en Ciencias en Ingeniería de Cómputo",
    Estancia: "Christ's Hospital, en Horsham, Inglaterra",
    Periodo: "27 junio 2023 - 1 agosto 2023",
    Actividades: "Realizó trabajos para Oxford International, con sede en Greenwich, Inglaterra. Colaborando con el equipo instalado en la escuela Christ's Hospital, en Horsham, Inglaterra",
  },
  {
    ID: 3,
    Foto: require("../images/Estancias/diana.jpeg"),
    Nombre: "Diana Patricia Barragán Vázquez",
    Alt: "Estudiante de la Maestría en Ciencias de la Computación",
    Estancia: "Escuela Técnica Superior de Ingeniería, Universidad de Sevilla, Departamento de Ingeniería de Sistemas y Automática",
    Periodo: "20 febrero 2023 - 20 junio 2023",
    Actividades: "Se investigaron estrategias de control de fuerza y control de velocidad, para posteriormente implementar modelos de control con dinámica inversa a brazos robóticos, teniendo siempre en cuenta que el objetivo principales era la precisión y rapidez de los efectores finales.",
  }
];
